import React, {useState, useEffect} from 'react';
import { InstagramEmbed } from 'react-social-media-embed';
import Grid from '@mui/material/Grid';
import MyCard from '../components/MyCard'
import 'pure-react-carousel/dist/react-carousel.es.css';

export default function FsgInstaStory(props) {
  const {activeContent} = props
  const [posts, setPosts] = useState([])

  useEffect(() => {
    const getInstaPosts = async () => {
      const token = 'IGQVJVRmd4TmlQcXdqSVFIYlVQeGo4ZAFh6UlFtOUxrZADdmQnJ2WDRfNk4wVEJGa0JuTTRsWTMtMUl2MjlFSldOd0NXUkdsa2VkSlpfNFFVY0VRdU1UQzFQUTFES05hWUZAVbnNwN18yU2RZAdTc0UGlYQQZDZD'
      const query = "id,username,timestamp,caption,media_url,media_type,permalink,children"
      const final_url = "https://graph.instagram.com/me/media?fields=" + query + "&access_token=" + token

      const response = await fetch(final_url);
      const data = await response.json();
      setPosts(data.data)

    };

    getInstaPosts();

    return () => {
      // this now gets called when the component unmounts
    };
  }, []);

  if (activeContent !== 'Home'){
    return <></>;
  }

  return(
    <Grid container spacing={2}>
      {posts.slice(0, 3).map((post, index) => {
        return (
            <Grid item xs={12} md={4} sx={{justifyContent: 'center', display: "flex"}}>
              <MyCard>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <InstagramEmbed url={post.permalink} width={328} />
                </div>
            </MyCard>
          </Grid>

        )
      })}
    </Grid>
  );
}
