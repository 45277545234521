import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import FootballSchedule from './FootballSchedule'
import FootballContact from './FootballContact'
import MyCard from '../MyCard'
import {getBoardMemberDetails} from '../club/BoardMember'

export default function FootballInfo(props) {

  return (
    <div id="FootballInfo">
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={6}>
        <MyCard sx={{textAlign: "left"}} header="Fussball">
          <Typography>
            Placeholder
          </Typography>
        </MyCard>
      </Grid>
      <Grid item xs={12} sm={12} md={3}>
        <FootballSchedule />
        <Box mt={2}></Box>
        <FootballContact
          contactDetails={getBoardMemberDetails('ceo')}
          position={'Spielausschuss'}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={3}>
        <FootballContact
          contactDetails={{
            title: 'Herr',
            firstName: 'Bülent',
            lastName: 'Kocatürk',
            email: 'info@fsg-wisselsheim.de',
            mobile: '+49 151 23083276'
          }}
          position={'Trainer'}
        />
        <Box mt={2}></Box>
        <FootballContact
          contactDetails={getBoardMemberDetails('secretary')}
          position={'Platzwart'}
        />
      </Grid>
    </Grid>
    </div>
  );
}
