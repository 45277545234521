import Box from '@mui/material/Box';
import SingleCardEntity from '../components/SingleCardEntity'
import {BernerImage, GigerImage, EssoImage, WagnerImage, CityImage, ImmoPowerImage, KvImage, AletterImage, AdditiveImage} from '../components/partner/PartnerImages'

export default function Partner(props){
  const {activeContent} = props

  if (activeContent !== 'Partner'){
    return <></>;
  }
  return (
    <SingleCardEntity header="Unsere Partner">
      <AdditiveImage />
      <BernerImage />
      <GigerImage />
      <EssoImage />
      <WagnerImage />
      <CityImage />
      <ImmoPowerImage />
      <KvImage />
      <AletterImage />
    </SingleCardEntity>
  );
}
